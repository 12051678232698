import React from "react";

export default function About() {
  return (
    <div style={{ paddingTop: "25px" }} id="about">
      <p>
        <b>Janet Mannion, RN, BSN, IBCLC</b> – Jan is an International Board
        Certified Lactation Consultant and a registered nurse. She worked at
        Piedmont Hospital in Atlanta in the Newborn Nursery and Neonatal
        Intensive Care Unit for 24 years before becoming a hospital based
        lactation consultant. For the past 13 years, she has worked as a
        lactation consultant at Piedmont Fayette Hospital. Her roles have
        included Family Care Center and NICU inpatient rounds, outpatient
        consults, teaching breastfeeding and other Family Care Center classes.
        She began the hospital’s monthly family support group, Baby Dayz. Jan
        teaches in the EPIC program of the AAP, providing breastfeeding
        education in medical offices and communities. Jan is a member of ILCA,
        International Lactation Consultant Association and CAPPA, Childbirth and
        Postpartum Professional Association.
      </p>
      <p>
        "I believe in the proven benefits of breastmilk for a baby’s growth and
        development and the rewards of a successful, happy breastfeeding
        experience. It is a privilege to provide you and your baby with loving
        lactation care so that you and your baby will love your lactation
        experience."
      </p>
    </div>
  );
}
